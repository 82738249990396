<template>
  <div class="buyCombe">
    <nav-bar
      title="购买套餐"
      rightText="购买记录"
      rightColor="#A77543"
      :clickRight="clickRight"
      :hasLeftClick="true"
      :clickLeft="clickLeft"
    ></nav-bar>
    <div class="wrapper">
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="flex">
          <div>
            <div class="num">{{ item.packageName }}</div>
            <div class="desc">{{ item.packageDesc }}</div>
          </div>
          <div class="price">￥{{ item.packagePrice }}</div>
        </div>
        <div class="toBuy">
          <div
            :class="[
              'buy',
              item.id == 0 && freePayFlag != 0 ? 'alreadybuy' : '',
            ]"
            @click="toBuyCombe(item)"
          >
            {{ (item.packagePrice == 0 && "试用") || "购买" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getEsPackageList, addBuyEsPackage } from "@/api";
import { Dialog, Toast } from "vant";
export default {
  name: "buyCombe",
  components: {
    NavBar,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      list: [],
      freePayFlag: "",
      payStatus: false,
    };
  },
  methods: {
    //购买记录
    clickRight() {
      this.$router.push({ name: "buyRecord" });
    },
    //去购买
    toBuyCombe(item) {
      if (item.packagePrice == 0) {
        if (this.freePayFlag != 0) {
          return;
        }
        addBuyEsPackage({
          epcSearchPackageId: item.id,
          settleType: 2,
          userId: sessionStorage.getItem("userId"),
          partyId: sessionStorage.getItem("partyId"),
        }).then((res) => {
          console.log(res);
          this.getCombeList();
          Dialog.alert({
            title: "提示",
            message:
              "您的7天免费试用套餐已激活，套餐余量可前往购买记录进行查看",
          });
        });
        return;
      }
      this.$router.push({ name: "choiceSettle", params: { id: item.id } });
    },
    //获取套餐列表
    getCombeList() {
      getEsPackageList({
        userId: sessionStorage.getItem("userId"),
        partyId: sessionStorage.getItem("partyId"),
      }).then((res) => {
        console.log(res);
        this.list = res.data.data.list;
        this.freePayFlag = res.data.data.freePayFlag;
      });
    },
    clickLeft() {
      if (this.payStatus) {
        var userId = sessionStorage.getItem("userId");
        var partyId = sessionStorage.getItem("partyId");
        var fromUrl = sessionStorage.getItem("fromUrl");
        var source = sessionStorage.getItem("source");
        this.$router.push({
          name: "index",
          query: { userId, partyId, fromUrl, source },
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    this.getCombeList();
    sessionStorage.removeItem("combe");
  },
  beforeRouteEnter(to, from, next) {
    console.log(from,'购买返回')
    if (from.name == "choiceSettle" || from.name == "buyRecord") {
      next((vm) => {
        vm.payStatus = true;
      });
    } else {
      next(vm=>{
        vm.payStatus = false;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.buyCombe {
  background: #f8f8f8;
  height: 100%;
  .wrapper {
    padding: 60px 10px 0;
    .list {
      margin-bottom: 10px;
      padding: 16px 15px 0 20px;
      background: #fff;
      border-radius: 10px;
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .num {
          color: #161616;
          font-size: 15px;
          font-weight: bold;
        }
        .desc {
          color: #767676;
          font-size: 13px;
        }
        .price {
          color: #fe703f;
          font-size: 12px;
        }
      }
      .toBuy {
        margin-top: 15px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #f3f3f3;
        .buy {
          width: 94px;
          text-align: center;
          height: 28px;
          line-height: 28px;
          background: linear-gradient(to right, #1677ff, #2f99ff);
          border-radius: 16px;
          color: #fff;
        }
        .alreadybuy {
          background: #ccc;
        }
      }
    }
  }
}
</style>